<template>
  <div class="plocyContent">
    <div class='plocy flex'>
       <news-item v-for="(option,index) in dataList" :key="index" :option="option"></news-item>
    </div>
    <pagination v-if='page.total > pageSize' @pageChange="pageChange" :total='page.total' :currentPage='page.currentPage' :pageSize='page.pageSize'></pagination>
  </div>
</template>
<script>
import newsItem from "./newsitem";
import pagination from "@/component/pagination/index.vue";
export default {
  data() {
    return {
      dataList: [],
      arr: [],
      pageNumber: 1,
      pageSize: 6,
      columnIds: [
        "d1707e23348143fb99537c9bddffcdb3",
        "3373f13e83b74f12a213a53db20a6657",
        "abb9308a63f64190b55633b83cc27e12"
      ],
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 6
      }
    };
  },
  components: {
    newsItem,
    pagination
  },
  created() {
    this.getData();
  },
  methods: {
    pageChange (item) {
      this.page.currentPage = item.page_index;
      this.page.pageSize = item.page_limit;
      this.getData(); //改变页码，重新渲染页面
    },
    async getData(opt) {
      this.pageNum = this.page.currentPage;
      const api = this.$fetchApi.dynamicNewsList.api;
      const data = await this.$fetchData.fetchPost(
        {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          columnId: this.columnIds[this.$route.query.idx]
        },
        api,
        "json"
      );
      if (data.code === "200") {
        this.dataList = data.result.rows;
        this.page.total = data.result.total;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.plocyContent{
  padding-bottom:40px;
  width: 100%;
}
.plocy {
  flex-wrap: wrap;
}
</style>