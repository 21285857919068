<template>
  <router-link
    tag="div"
    :to="{name:'plocydetail',query:{
        idx:activeNum,
        id:option.informationId,
      }}"
    class="plocy_item"
  >
    <img :src="option.mainImgUrl" alt />
    <div class="plocy_item_content">
      <P class="title ellipsis">{{option.title}}</P>
      <!-- <div class="plocy_wrap" v-html="option.content" title="点击查看详情"></div> -->
      <p class="plocy_time">{{option.releaseDate}}</p>
    </div>
  </router-link>
</template>
<script>
export default {
  data() {
    return {
      activeNum: -1
    };
  },
  created() {
    this.activeNum = this.$route.query.idx;
  },
  props: {
    option: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    $route() {
      this.activeNum = this.$route.query.idx;
    }
  }
};
</script>
<style lang="less" scoped>
.plocy_item {
  width: 300px;
  background-color: #ffffff;
  // box-shadow: 0px 3px 7px 0px #d9d9d9;
  border: solid 1px #d9d9d9;
  margin: 10px 20px 0 0;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.7s;
  &:hover {
    box-shadow: 0 0 20px #d9d9d9;
    transform: scale(1.03);
  }
  img {
    width: 100%;
    height:200px;
    cursor: pointer;
  }

  .plocy_item_content {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    .title {
      height: 20px;
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 20px;
      height:40px;
      letter-spacing: 0px;
      color: #333;
      margin: 10px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space:normal;
    }
    .plocy_wrap {
      width: 100%;
      font-family: SourceHanSansCN-Regular;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 12px;
      letter-spacing: 0px;
      color: #999999;
      line-height: 20px;
      &:hover {
        color: #265ae1;
        text-decoration: underline;
      }
    }
    .plocy_time {
      height: 24px;
      font-family: SourceHanSansCN-Regular;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 24px;
      letter-spacing: 0px;
      color: #999999;
    }
    
  }
  
}
.plocy_item:nth-child(3n){
  margin-right:0;
}
</style>